.msg {
  text-align: left;
  margin-bottom: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  font-size: small;
}

.person {
  color: #a2aaaa;
  padding-left: 20px;
}

.date {
  color: #a2aaaa;
}

.message {
  padding: 10px 20px;
  background-color: #f0eff4;
  border-radius: 15px;
}

.message.mine {
  background-color: #e7f1ff;
}

.direct {
  color: #f17472;
}

.imagePreview {
  max-width: 100%;
}