.input {
  width: 100%;
  border-top: 1px solid lightgray;
  padding-top: 3px;
}

.inputField {
  width: calc(100% - 40px);
  border: none;
  /*background-color: #e5e5e5;*/
  padding: 10px 20px;
}

.header {
  padding: 3px 20px;
  font-size: small;
  display: flex;
  justify-content: space-between;
}

.select {
  border: none;
  background-color: whitesmoke;
  border-radius: 10px;
  padding: 5px;
  font-size: small;
}

.fileUpload {
  border-radius: 3px;
  border: 1px solid gray;
  padding: 3px 5px;
  cursor: pointer;
}
.fileUpload:hover {
  background-color: #02a6ff;
  color: #fff;
}

.hidden {
  display: none;
}
