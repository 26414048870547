.messages {
  overflow: auto;
  flex: 1;
}

.chatWindow {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
